.join{
display: flex;
padding: 0 2rem;
gap: 16rem;
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    position:absolute;
    width: 10.5rem;
    border:2px solid orange;
    border-radius: 20%;
    margin: -10px 0;
}


.right-j{
    display: flex;
     justify-content:center;
    align-items:flex-end ;
}
.email-container {
    display: flex;
    gap: 2rem;
    background-color: grey;
    padding: 1rem   2rem;;
}


.email-container > input{
    background-color: transparent;
    border: none;
    outline: none;
    color: black;
}

::placeholder{
    color: lightgray;
   
}
.btn-j{
    background-color: orange;
    color:white;
}

@media screen and (max-width:768px) {
    .join{
        flex-direction: column;
            gap: 1rem;
    }
    .left-j{
        font-size: x-large;
     flex-direction: column;
    }
    .right-j{
        padding: 2rem;
    }
    
}